import React from 'react';
import { Heading } from '@snsw-gel/react';
import { ExternalTextLink } from 'components/Hyperlinks';
import { priceDKTOnlineTest, priceLearnerLicence } from 'config/constants';
import { nswLicencesFees } from 'consts/links';
import * as Styled from './TestFee.styled';

const TestFee: React.FC = () => (
  <>
    <Heading level={4}>Test fee</Heading>
    <p>
      You will need to pay for the DKT online test fee and your licence
      application fee.
    </p>
    <Styled.CostDescriptionList>
      <dt>Learner licence</dt>
      <dd data-testid='price-learners-licence'>${priceLearnerLicence}</dd>
      <dt>DKT online test</dt>
      <dd data-testid='price-dkt-online'>${priceDKTOnlineTest}</dd>
      <Styled.TotalDT>
        <strong>Total</strong>
      </Styled.TotalDT>
      <Styled.TotalDD>
        <strong>${totalTestFee}</strong>
      </Styled.TotalDD>
    </Styled.CostDescriptionList>
    <p>
      Please note these fees are subject to change, for more information about fees refer to{' '}
      <ExternalTextLink
        id='vistNswLicencesFeesLink'
        href={nswLicencesFees}
      >
        nsw.gov.au
      </ExternalTextLink>
      .
    </p>
  </>
);

export default TestFee;

const totalTestFee = priceLearnerLicence + priceDKTOnlineTest;
