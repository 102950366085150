const checkEligibilityPageStep = 'Check eligibility';
const checkEligibility = {
  path: '/eligibility',
  pageStep: checkEligibilityPageStep,
};
const checkEligibilityFoundExistingCustomer = {
  path: '/eligibility/existing-customer',
  pageStep: checkEligibilityPageStep,
};
const confirmation = {
  path: '/confirmation',
  pageStep: 'Confirmation',
};
const confirmationSupport = {
  path: '/view-receipt/:receiptUUID',
  pageStep: 'Confirmation',
};
const reviewAndSubmitPageStep = 'Review and submit';
const reviewAndSubmit = {
  path: '/review',
  pageStep: reviewAndSubmitPageStep,
};
const postPoi = {
  path: '/post-poi',
  pageStep: reviewAndSubmitPageStep,
};
const passedTest = {
  path: '/dkt-passed',
  pageStep: 'You’ve passed DKT online',
};
const landingPage = {
  path: '/',
  pageStep: 'Welcome',
};

const genericError = {
  path: '/error',
  pageStep: 'Error',
};
const errorBusiness = {
  path: '/error/business',
  pageStep: 'Error',
};
const errorSystem = {
  path: '/error/system',
  pageStep: 'Error',
};
const errorEligibility = {
  path: '/error/eligibility',
  pageStep: 'Error',
};
const errorHasLicenceDktExpired = {
  path: '/error/licence',
  pageStep: 'Error',
  pageSubType: 'licence',
};
const errorInNSWAddress = {
  path: '/error/nsw-address',
  pageStep: 'Error',
};

const routes = {
  checkEligibility,
  checkEligibilityFoundExistingCustomer,
  confirmation,
  confirmationSupport,
  reviewAndSubmit,
  postPoi,
  landingPage,
  passedTest,
  genericError,
  errorBusiness,
  errorSystem,
  errorEligibility,
  errorHasLicenceDktExpired,
  errorInNSWAddress,
};

export default routes;
