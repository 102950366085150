import React from 'react';
import {
  ServiceCentreHyperlink,
  TelephoneContactNumberHyperlink,
} from 'components/Hyperlinks';
import TemplateErrorPage from './TemplateErrorPage/TemplateErrorPage';

const NSWAddressErrorPage: React.FC = () => {
  return (
    <TemplateErrorPage
      pageTitle="We're sorry about this"
      defaultAlertTitle='We are unable to validate your address details'
      analyticsErrorType='validation error'
      contactDescription={contactDescription}
    />
  );
};

export default NSWAddressErrorPage;

const contactDescription = (
  <p>
    Please call us on <TelephoneContactNumberHyperlink /> or visit a{' '}
    <ServiceCentreHyperlink />. We apologise for any inconvenience.
  </p>
);
