import styled, { css } from 'styled-components';
import { tokens } from '@snsw-gel/react';

export const CostDescriptionList = styled.dl`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0;
  max-width: 32rem;

  > dd {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const totalBorderAndSpacing = css`
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: solid ${tokens.colors.grey.geyser} 2px;
`;
export const TotalDT = styled.dt`
  ${totalBorderAndSpacing}
  width: inherit;
  padding-right: 0;
`;

export const TotalDD = styled.dd`
  ${totalBorderAndSpacing}
  width: min-content;
`;
