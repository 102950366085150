import React, { useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { AccordionItem, Heading } from '@snsw-gel/react';
import { AccordionWithoutExpandCollapse } from 'components/AccordionStyling/AccordionStyling.styled';
import getNewActiveAccordionSet from 'components/AccordionStyling/getNewActiveAccordionSet';
import { ReducedLeftPaddingUl } from 'components/ListStyling/ListStyling.styled';
import list1Markdown from './list1.md';
import list2Markdown from './list2.md';

const ProofOfIdentity: React.FC = () => {
  const [activeAccordion, setActiveAccordion] = useState(
    new Set<accordionKeyIds>([])
  );

  return (
    <>
      <Heading level={4}>Proof of identity documents</Heading>
      <p>
        You will need to provide 2 current identity documents. One from each
        list below:
      </p>

      <AccordionWithoutExpandCollapse
        id='identity-documents'
        expandedKeys={activeAccordion}
        onExpandedChange={(newState) => {
          const newAccordion = getNewActiveAccordionSet(
            activeAccordion,
            newState
          );
          setActiveAccordion(newAccordion as Set<accordionKeyIds>);
        }}
      >
        <AccordionItem id='identity-documents-list-1' title='List 1'>
          <ReactMarkdown components={accordionMarkdownComponents}>
            {list1Markdown}
          </ReactMarkdown>
        </AccordionItem>
        <AccordionItem id='identity-documents-list-2' title='List 2'>
          <ReactMarkdown components={accordionMarkdownComponents}>
            {list2Markdown}
          </ReactMarkdown>
        </AccordionItem>
      </AccordionWithoutExpandCollapse>
    </>
  );
};

export default ProofOfIdentity;

type accordionKeyIds =
  | 'identity-documents-list-1'
  | 'identity-documents-list-2';

const accordionMarkdownComponents: Components = {
  // eslint-disable-next-line react/display-name
  ul: ({ children, ...rest }) => (
    <ReducedLeftPaddingUl {...rest}>{children}</ReducedLeftPaddingUl>
  ),
};
