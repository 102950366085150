export const drupalPageLocation =
  'https://www.service.nsw.gov.au/transaction/driver-knowledge-test-online';
export const TUTDAppName = 'driver-knowledge-test';
export const TUTDLabel = 'How was your DKT online enrolment experience?';
export const transactionName = 'DKT';
export const receiptFileName = 'receipt.pdf';

// callback from profile connect will have an ID attached eg. ?sessionId=1234-5678-91011
export const profileConnectCallbackQueryParameter = 'sessionId';

// price in dollars
export const priceLearnerLicence = 30;
export const priceDKTOnlineTest = 55;
