import routes from 'config/routes';

export const mappedSystemErrorCodesToRoute = {
  // System
  SYS0000002: routes.errorSystem.path,
  SYS0000003: routes.errorSystem.path,
  SYS0000004: routes.errorSystem.path,
  SYS0000006: routes.errorSystem.path,

  // Business: SNSW defect
  BUS5000101: routes.errorBusiness.path,
  BUS5000102: routes.errorBusiness.path,
  BUS5000108: routes.errorBusiness.path,
  BUS5000109: routes.errorBusiness.path,
  BUS5000113: routes.errorBusiness.path,
  BUS5000114: routes.errorBusiness.path,
  BUS0000002: routes.errorBusiness.path,

  // Business: enrol customer
  BUS5000103: routes.errorEligibility.path,
  BUS5000106: routes.errorEligibility.path,
  BUS5000104: routes.errorBusiness.path,
  BUS5000105: routes.errorBusiness.path,

  // Business: existing customer
  BUS5000107: routes.checkEligibilityFoundExistingCustomer.path,

  // In page alerts
  BUS5000110: null,
  BUS5000111: null,
  BUS5000112: null,

  // SNSW custom errors
  DPID_MISSING: routes.errorInNSWAddress.path, // cannot validate address
  DL_DKT_EXPIRED: routes.errorHasLicenceDktExpired.path, // customer already has licence, DKT has expired
};

export const genericSystemFailureError = {
  identifier: 'SYSTEM_FAILURE',
  classification: 'SYSTEM',
  severity: 'ERROR',
  messageTitle: 'Our system is temporarily unavailable',
  messageDescription:
    'Please try again later, call us on [13 77 88](tel:137788) or visit a [service centre](https://www.service.nsw.gov.au/service-centre). We apologise for any inconvenience.',
  message: '',
};
